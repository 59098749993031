<template>
    <div>
        <div v-if="show">
            <div class="card" v-show="operation !== 'detail'">
                <div class="card-header card-header-flex pb-2">
                    <div class="w-100 mt-2">
                        <div class="row">
                            <div class="col-8">
                                <h5 class="mt-3 ml-0 mr-3 mb-2">
                                    <strong>
                                        <template v-if="operation === null">{{$t('title.stock')}}</template>
                                        <template v-else>{{ $t(operationTitle) }}</template>
                                    </strong>
                                </h5>
                            </div>
                            <div class="col-4 text-right">
                                <div v-if="operation === null">
                                    <div class="mt-3">
                                        <!-- Using components -->
                                        <b-input-group class="mt-3">
                                            <b-form-input type="search" class="form-control form-control-sm"
                                                          :placeholder="$t('input.whatAreYouLookingFor')"
                                                          v-on:keyup.enter="handleSearch"
                                                          v-model="search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                          variant="outline-info"
                                                          @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                          v-if="$global.hasPermission('ordersownview') || $global.hasAnyRole(['superadmin', 'efl'])">
                                                    <i class="fa fa-filter"></i>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.resetList')"
                                                          variant="outline-info"
                                                          @click="handleResetClick()" v-b-tooltip.hover>
                                                    <i class="fa fa-refresh"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div v-else>
                                    <b-button variant="warning" size="sm" class="mt-3"
                                              @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </div>
                        </div><!-- /.row -->
                    </div><!-- /.w-100 -->
                </div><!-- /.card-header -->
                <div class="card-body">
                    <div class="orders-table">
                        <b-table hover responsive
                                 :busy="listingLoading"
                                 :items="dataSource"
                                 :fields="columns"
                                 :sort-by.sync="sortField"
                                 ref="orderTable"
                                 @sort-changed="handleSortChange">
                            <template v-slot:cell(order_id)="{ detailsShowing, item, index, rowSelected}">
                                <div class="position-relative">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                    </template>
                                    <a @click="toggleDetails(item)" class="d-inline in-center">
                                        <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                    </a>
                                    <span class="indicator-added-inbound-load"
                                          v-if="item.load_status < 7 && item.added_in_load_at"
                                          :title="$t('title.inboundAdded')" v-b-tooltip.hover></span>
                                    <span class="indicator-added-outbound-load"
                                          v-if="item.load_status >= 10 && item.load_status < 17"
                                          :title="$t('title.outboundAdded')" v-b-tooltip.hover></span>
                                    {{ (item.order_id ? item.order_id : '') }}
                                </div>
                            </template>
                            <template v-slot:cell(status)="record">
                                {{ record.item._status }}
                            </template>
                            <template v-slot:cell(load_status)="{item}">
                                <div>
                                    <ol-status :status="item.load_status"></ol-status>
                                </div>
                                <tr-status v-if="item.tr_status > 0" :status="item.tr_status"
                                           :type="item.tr_status_type"></tr-status>
                                <template v-if="item.load_status == '9'"><br>
                                    <b-badge v-if="item._additional_services > 0 && item.damage > 0" variant="info">
                                        {{$t('title.serviceOrders')}} & {{$t('title.damaged')}}
                                    </b-badge>
                                    <b-badge v-if="item._additional_services > 0 && !item.damage > 0" variant="info">
                                        {{$t('title.serviceOrders')}}
                                    </b-badge>
                                    <b-badge v-if="!item._additional_services > 0 && item.damage > 0" variant="info">
                                        {{$t('title.damaged')}}
                                    </b-badge>
                                    <b-badge v-if="!item._additional_services > 0 && !item.damage > 0" variant="info">{{
                                        (_.find(item.load_status_Histories, {status: 9}).comments) ?
                                        _.find(item.load_status_Histories, {status: 9}).comments : $t('title.other')}}
                                    </b-badge>
                                </template>
                                <template v-if="item.client_affected"><br>
                                    <b-badge v-if="item.client_affected_priority === 1" variant="danger"
                                             :title="$t('title.clientAffected') + ' | ' + $t('title.high') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')"
                                             v-b-tooltip.hover>{{$t('title.clientAffected')}}
                                    </b-badge>
                                    <b-badge v-if="item.client_affected_priority === 2" variant="warning"
                                             :title="$t('title.clientAffected') + ' | ' + $t('title.medium') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')"
                                             v-b-tooltip.hover>{{$t('title.clientAffected')}}
                                    </b-badge>
                                    <b-badge v-if="item.client_affected_priority === 3" variant="secondary"
                                             :title="$t('title.clientAffected') + ' | ' + $t('title.low') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')"
                                             v-b-tooltip.hover>{{$t('title.clientAffected')}}
                                    </b-badge>
                                </template>
                                <template v-if="item.load_status == 8 && _.isEmpty(item.client_tr_at)"><br>
                                    <b-badge v-if="item.client_tr_at && _.isEmpty(item.client_tr_accept_at)"
                                             variant="dark">{{$t('title.deliveryRequest')}}
                                    </b-badge>
                                    <b-badge v-if="!_.isEmpty(item.client_tr_accept_at)" variant="dark">
                                        {{$t('title.readyToBeLoaded')}}
                                    </b-badge>
                                </template>
                            </template>
                            <template v-slot:cell(brand_id)="record">
                                {{ (record.item.brand ? record.item.brand.title : '') }}
                                <div>{{ (record.item.model ? record.item.model.title : '') }}</div>
                            </template>
                            <template v-slot:cell(from_location_id)="{item}">
                                <span v-if="item.from_location">
                                    <span><img
                                        :src="'/resources/images/flags/' + (_.find(dropdowns.countries, {id: item.from_location.country}) ? _.find(dropdowns.countries, {id: item.from_location.country}).sort_name : '') + '.png'"
                                        class="mr-2"></span>
                                    <span v-if="item.from_location.city"
                                          :title="item.from_location.street  + ', ' + item.from_location.street_no + ', ' + item.from_location.city + ', ' + item.from_location.zip + ', ' + item.from_location.country"
                                          v-b-tooltip.hover>{{item.from_location.city}} </span>
                                </span>
                            </template>
                            <template v-slot:cell(to_location_id)="{item}">
                                <span v-if="item.to_location">
                                    <span><img
                                        :src="'/resources/images/flags/' + (_.find(dropdowns.countries, {id: item.to_location.country}) ? _.find(dropdowns.countries, {id: item.to_location.country}).sort_name : '') + '.png'"
                                        class="mr-2"></span>
                                    <span v-if="item.to_location.city"
                                          :title="item.to_location.street + ', ' + item.to_location.street_no + ', ' + item.to_location.city + ', ' + item.to_location.zip + ', ' + item.to_location.country"
                                          v-b-tooltip.hover>{{item.to_location.city}} </span>
                                </span>
                            </template>
                            <template v-slot:row-details="{ item }">
                                <b-card>
                                    <b-list-group flush>
                                        <b-list-group-item><strong>{{$t('column.importerNumber')}}</strong>:
                                            {{item.invoice_number_importer}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.comment')}}</strong>: {{item.comments}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.inboundSupplierCarrier')}}</strong>:
                                            {{item.supplier_carrier ? item.supplier_carrier.name: ''}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.inboundSupplierCompound')}}</strong>:
                                            {{item.supplier_compound ? item.supplier_compound.name: ''}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.outboundSupplierCarrier')}}</strong>:
                                            {{item.outbound_supplier_carrier ? item.outbound_supplier_carrier.name :
                                            ''}}
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <strong>{{$t('title.destinationLocation')}}</strong>:
                                            <template v-if="item.dealer_location">
                                                <print-ad :item="item.dealer_location"></print-ad>
                                            </template>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </template>
                            <template v-slot:cell(logistic_type)="record">
                                <span class="text-capitalize">
                                    <span
                                        v-if="record.item.tr_status_type == 'outbound'">{{$t('title.outbound')}}</span>
                                    <span
                                        v-if="record.item.tr_status_type == 'reexport'">{{$t('title.reexport')}}</span>
                                    <span
                                        v-if="record.item.tr_status_type == 'dd'">D2D</span>
                                    <span v-if="record.item.tr_status_type == 'cc'">CC</span>

                                </span>
                            </template>
                            <template v-slot:cell(action)="record">
                                <a @click="handleEditClick(record.item)"
                                   v-if="record.item.tr_status <= 2 && (record.item.load_status == 8 || record.item.load_status == 9)"
                                   :title="$t('button.title.switchStatus')"
                                   class="ml-1" v-b-tooltip.hover>
                                    <i class="fe fe-edit"></i>
                                </a>
                                <a @click="setOperation('detail', record.item.id)"
                                   :title="$t('button.title.detailItem')"
                                   class="ml-1"
                                   v-b-tooltip.hover>
                                    <i class="icmn-info"></i>
                                </a>
                            </template>
                        </b-table><!-- /.b-table -->
                        <div class="clearfix">
                            <div class="float-left ">
                                <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                               size="sm"></b-form-select>
                            </div>
                            <div class="float-right">
                                <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                                ></b-pagination>
                            </div><!-- /.pull-right -->
                        </div><!-- /.clearfix -->
                    </div><!-- /.orders-table-->
                    <div class="orders-operation">
                        <a-drawer
                            placement="right"
                            :width="'360px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="handleOperationClose"
                            :visible="formFields.visible"
                            :zIndex="10"
                            :title="$t(operationTitle)"
                        >
                            <form @submit.prevent="handleSubmit" autocomplete="off">
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$t('input.status')+' *'"
                                            label-for="status"
                                            :invalid-feedback="formErrors.first('status')">
                                            <b-form-select
                                                id="status"
                                                v-model="formFields.status"
                                                :options="dropdowns.status"
                                                value-field="id"
                                                text-field="label"
                                                :state="((formErrors.has('status') ? false : null))"
                                            ></b-form-select>
                                        </b-form-group>
                                        <template v-if="formFields.status === 9">
                                            <b-form-group label="ETR">
                                                <b-form-datepicker
                                                    placeholder=""
                                                    v-model="formFields.etr"
                                                    :state="((formErrors.has('etr') ? false : null))"
                                                    class="mb-2"></b-form-datepicker>
                                            </b-form-group>
                                            <b-form-group
                                                :label="$t('input.controllerName')"
                                                label-for="controller_name"
                                                :invalid-feedback="formErrors.first('controller_name')">
                                                <b-form-input
                                                    id="controller_name"
                                                    v-model="formFields.controller_name"
                                                    type="text"
                                                    :state="((formErrors.has('controller_name') ? false : null))"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </template>
                                        <b-form-group
                                            :label="$t('input.comment')"
                                            label-for="comment"
                                            :invalid-feedback="formErrors.first('comments')">
                                            <b-textarea
                                                id="comment"
                                                v-model="formFields.comments"
                                                type="text"
                                                :state="((formErrors.has('comments') ? false : null))"
                                                @focus="$event.target.select()"
                                            ></b-textarea>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <div class="drawer-footer">
                                        <b-button
                                            size="sm"
                                            type="submit"
                                            variant="primary"
                                            :disabled="global.pendingRequests > 0"
                                            v-b-tooltip.hover :title="$t('button.title.save')"
                                        >
                                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                                         size="12px"
                                                         v-if="global.pendingRequests > 0"></clip-loader>
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('button.save')}}
                                        </b-button>
                                        <b-button variant="warning" class="ml-3"
                                                  size="sm" @click="handleOperationClose()"
                                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                        </b-button>
                                    </div>
                                </b-row><!--/b-row-->
                            </form><!--/form-->
                        </a-drawer>
                    </div><!--/.orders-operation-->
                    <div class="filter-container">
                        <a-drawer
                            placement="left"
                            :width="'360px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="filters.visible = !filters.visible"
                            :visible="!operation && filters.visible"
                            :zIndex="10"
                            :title="$t('title.advanceFilters')"
                        >
                            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.brands')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.brands"
                                                placeholder=""
                                                v-model="filters.brands"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.models')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.models"
                                                placeholder=""
                                                v-model="filters.models"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.code')">
                                            <treeselect
                                                :multiple="true"
                                                :options=" _.uniqBy(_.filter(dropdowns.configCode, ({ model_id }) => _.every([_.includes(filters.models, model_id)])),'id')"
                                                placeholder=""
                                                v-model="filters.configCode"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.clients')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.clients"
                                                placeholder=""
                                                v-model="filters.clients"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.dealers')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.dealers"
                                                placeholder=""
                                                v-model="filters.dealers"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.loadingPoint')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.fromLocations"
                                                placeholder=""
                                                v-model="filters.fromLocations"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.unloadingPoint')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.toLocations"
                                                placeholder=""
                                                v-model="filters.toLocations"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.status')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.status"
                                                placeholder=""
                                                v-model="filters.status"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('title.additionalInformation')">
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.statusAdditionalInfo"
                                                placeholder=""
                                                v-model="filters.status_additional_info"/>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col>
                                        <b-form-group
                                            :label="$t('input.orderTransportStatus')">
                                            <treeselect
                                                :multiple="true"
                                                :options="[{id:1,label:'Pending'},{id:2,label:'Rejected'},{id:3,label:'Approved'}]"
                                                placeholder=""
                                                v-model="filters.orderTransportStatus"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.transportRequestType')">
                                            <treeselect
                                                :multiple="true"
                                                :options="[{id:'outbound',label:'outbound'},{id:'cc',label:'cc'},{id:'reexport',label:'reexport'}]"
                                                placeholder=""
                                                v-model="filters.transportRequestType"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.fromAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.toAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row>
                                <div class="drawer-footer">
                                    <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                              class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                        {{$t('button.close')}}
                                    </b-button>
                                    <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                              :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                        {{$t('button.reset')}}
                                    </b-button>
                                    <b-button size='sm' variant="primary" button="submit" type="filled"
                                              :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                        {{$t('button.apply')}}
                                    </b-button>
                                </div><!-- /.drawer-footer -->
                            </form>
                        </a-drawer>
                    </div><!-- /.filter-container -->
                </div><!-- /.card-body-->
            </div><!-- /.card -->
            <div v-if="operation === 'detail'">
                <detail :handle-close-operation="handleOperationClose"></detail>
            </div>
        </div>

    </div><!--/div-->
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from './detail'

    const FORM_STATE = {
        status: null,
        visible: false,
        comments: null,
        controller_name: null,
        etr: null,
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        configCode: [],
        dealers: [],
        clients: [],
        fromLocations: [],
        toLocations: [],
        orderTransportStatus: [],
        transportRequestType: [],
        status: [],
        status_additional_info: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.orderId'),
            key: 'order_id',
            sortable: true,
            stickyColumn: true,
        },
        {
            label: self.$t('column.vin'),
            key: 'vin_number',
            sortable: true,
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.unLoadingPoint'),
            key: 'to_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.brand') + '/' + self.$t('column.model'),
            key: 'brand_id',
            sortable: true,
        },
        {
            label: self.$t('column.status'),
            key: 'load_status',
            sortable: true,
        },
        {
            label: self.$t('column.logisticType'),
            key: 'logistic_type',
            sortable: false,
        },
        {
            label: self.$t('column.action'),
            class: 'text-right',
            key: 'action',
            width: 150,
        }
    ];

    export default {
        mixins: [ListingMixin],
        components: {
            Treeselect,
            Detail
        },
        data() {
            return {
                operationTitle: 'title.orders',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'orders/compound/supplier',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    countries: [],
                    clients: [],
                    brands: [],
                    models: [],
                    configCode: [],
                    dealers: [],
                    locations: [],
                    fromLocations: [],
                    toLocations: [],
                    status: [
                        {id: 8, label: this.$t('title.stocked')},
                        {id: 9, label: this.$t('title.damaged')},
                        {id: 10, label: this.$t('title.serviceOrders')}
                    ],
                    statusAdditionalInfo: [
                        {id: -1, label: this.$t('title.showOnlyStockOrders')},
                        {id: 1, label: this.$t('title.requested')},
                        {id: 2, label: this.$t('title.rejected')},
                        {id: 3, label: this.$t('title.confirmed')},
                        {id: 4, label: this.$t('title.clientAffected')},
                        {id: 5, label: this.$t('title.deliveryRequest')},
                        {id: 6, label: this.$t('title.readyToBeLoaded')},
                        {id: 7, label: this.$t('title.serviceOrders')},
                        {id: 8, label: this.$t('title.damaged')},
                        {id: 9, label: this.$t('title.serviceOrders') + ' | ' + this.$t('title.damaged')},
                    ],
                },
                show: true,
            }
        },
        mounted() {
            this.getCountries();
            this.getClients();
            this.getDealers();
            this.getBrands();
            this.getModels();
            this.getLocations();
            this.$title = this.$t('title.stock')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})

                try {
                    const response = await request({
                        url: 'orders/compound/supplier/update/status',
                        method: 'post',
                        data: {
                            ...this.formFields,
                            etr: (this.formFields.etr ? this.$global.dateToUtcDate(this.formFields.etr, 'YYYY-MM-DD', 'YYYY-MM-DD') : null),
                        },
                    })

                    this.itemUpdated()
                    this.formFields = {...FORM_STATE}
                    this.loadList()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleEditClick(item) {
                this.operationTitle = this.$t('title.switchStatus')
                this.formFields.id = item.id
                this.formFields.status = 8
                this.formFields.visible = true
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data
                    this.dropdowns.configCode = data.map(item => ({
                        id: item.version_code,
                        label: item.version_code,
                        model_id: item.id
                    }))

                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.configCode = []
                }
            },
            async getClients() {
                try {
                    const response = await request({
                        url: '/dropdowns/clients',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.clients = data

                } catch (e) {
                    this.dropdowns.clients = []
                }
            },
            async getDealers() {
                try {
                    const response = await request({
                        url: '/dropdowns/dealers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.dealers = data
                } catch (e) {
                    this.dropdowns.dealers = []
                    this.dropdowns.dealersLocations = []
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data
                    this.dropdowns.fromLocations = data
                    this.dropdowns.toLocations = data
                } catch (e) {
                    this.dropdowns.locations = []
                    this.dropdowns.fromLocations = []
                    this.dropdowns.toLocations = []
                }
            },
            handleSelectModelsSelect(changeValue) {
                this.filters.configCode = []
            },
            hasListAccess() {
                return this.$global.hasPermission('ordersownview');
            },
            refreshList() {
                this.loadList()
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .w-220 {
        width: 220px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    @media screen and (max-width: 1379px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    .indicator-added-inbound-load {
        bottom: -4px;
        left: 0;
    }

    .indicator-added-outbound-load {
        bottom: -4px;
        left: 15px;
    }

    .indicator-added-priority {
        bottom: -13px;
        left: 0;
    }
</style>
